<template>
  <div class="form-group" :class="{ 'mb-8': hasBottomMargin }">
    <label :for="name" class="mb-0">
      <span class="fas fa-asterisk text-danger"></span>
      {{ label }}
      <button
        v-if="tooltipText"
        type="button"
        class="btn btn-ui btn-sm py-0 px-1 mt-n2"
        data-toggle="tooltip"
        data-placement="bottom"
        :title="tooltipText"
        :aria-label="tooltipText"
        :data-bs-original-title="tooltipText"
        :data-original-title="tooltipText"
      >
        <span class="fas fa-question"></span>
      </button>
    </label>
    <div class="mb-1 text-muted">
      {{ description }}
    </div>
    <div class="input-group mb-3">
      <span class="input-group-text" :class="{ 'is-invalid': !!errorMessage, success: meta.valid }"
        >$</span
      >
      <input
        :id="name"
        :name="name"
        :type="type"
        class="form-control"
        :class="{ 'is-invalid': !!errorMessage, success: meta.valid }"
        @input="handleChange"
        @blur="handleBlur"
      />
    </div>
    <div v-show="errorMessage">
      <small class="text-danger mb-0">
        {{ errorMessage }}
      </small>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  props: {
    type: {
      type: String,
      default: "number",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    hasBottomMargin: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style scoped>
.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-text.is-invalid {
  border-color: #b00003;
  color: #b00003;
}
</style>
