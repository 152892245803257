<template>
  <div class="official-banner py-1">
    <div class="container">
      <div class="row align-items-center">
        <LanguagePicker />
        <div class="col-lg-7">
          <div class="media align-items-center">
            <img
              src="../assets/img/Louisiana-State-Seal.png"
              alt="Louisiana State Seal."
              class="img-official"
            />
            <div class="media-body ml-2">
              <p class="mt-lg-1 mb-0">
                {{ t("govBanner.officialSite") }}
                <button
                  class="expander btn btn-link border-0 bg-transparent shadow-none btn-sm text-white collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#officialBannerCollapse"
                  aria-expanded="false"
                  aria-controls="officialBannerCollapse"
                >
                  {{ t("govBanner.officialSiteHow") }}
                  <span class="fas fa-angle-down" aria-hidden="true"></span>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-1 collapse" id="officialBannerCollapse">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="media">
            <img src="@/assets/img/icon-dot-gov.svg" alt="" class="align-self-start img-official" />
            <div class="media-body ml-2">
              <p class="mb-0">
                <span class="font-weight-bolder">{{ t("govBanner.govOfficial") }}</span>
              </p>
              <p class="mb-0">{{ t("govBanner.govOfficialMeaning") }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
          <div class="media">
            <img src="@/assets/img/icon-https.svg" alt="" class="align-self-start img-official" />
            <div class="media-body ml-2">
              <p class="mb-0">
                <span class="font-weight-bolder">{{ t("govBanner.siteSecure") }}</span>
              </p>
              <p class="mb-0">{{ t("govBanner.siteSecureMeaning") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import LanguagePicker from "@/components/LanguagePicker.vue";

export default {
  components: { LanguagePicker },
  setup() {
    const { t } = useI18n();

    return { t };
  },
};
</script>
