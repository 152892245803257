<template>
  <div class="bg-danger text-white py-5" role="alert" v-if="hasActiveEvent">  
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="mb-0">
            <span class="fas fa-exclamation-triangle" aria-hidden="true"></span> 
            {{ t("shared.eventMessage")}}         
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    const hasActiveEvent = (t("shared.activeEvent") === "true") ? true : false;

    const disasterMessage = hasActiveEvent 
      ? `${t("shared.eventName")}` 
      : '';

    return { t, hasActiveEvent, disasterMessage };
  },
};
</script>
