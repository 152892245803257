<template>
  <nav class="navbar navbar-expand-lg bg-primary text-white">
    <div class="container">
      <router-link
        class="brand-link nav-link"
        :to="{ name: 'Home', params: { locale: locale } }"
        exact
      >
        <h1 class="mb-0 text-white">
          <img
            src="@/assets/img/Louisiana-State-Seal.png"
            class="header-logo d-none d-lg-inline"
            alt="State Seal"
          />
          <span class="title pl-2">{{ t("shared.appTitle") }}</span>
        </h1>
      </router-link>
      <button
        class="navbar-toggler text-white ml-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navigationItems"
        aria-controls="navigationItems"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="fas fa-bars"></span> {{ t("shared.menuTitle") }}
      </button>
      <div id="navigationItems" class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link
              class="nav-link text-white"
              active-class="active"
              :to="{ name: 'Home', params: { locale: locale } }"
              exact
            >
              {{ t("routes.home") }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link text-white"
              active-class="active"
              :to="{ name: 'DSNAP', params: { locale: locale } }"
              exact
            >
              {{ t("routes.dsnap") }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();

    return { t, locale };
  },
};
</script>

<style scoped>
.header-logo {
  width: 45px;
  height: 45px;
}
.title {
  vertical-align: middle;
}
</style>
