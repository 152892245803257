<template>
  <PageTitle>
    <h2 class="page-title-text mb-2">{{ t("dsnapPage.title") }}</h2>
    <p class="mb-0">
      {{ t("dsnapPage.subtitle") }}
    </p>
  </PageTitle>

  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <Form
              :validation-schema="schema"
              @submit="calculateBenefits"
              @invalid-submit="onInvalidSubmit"
            >
              <div class="row">
                <div class="col-12">
                  <h3 class="legend text-primary mt-0">{{ t("dsnapPage.householdTitle") }}</h3>
                  <p class="mb-0"></p>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <MoneyInput
                    name="income"
                    :label="t('dsnapPage.incomeInputLabel')"
                    :description="t('dsnapPage.incomeInputDescription')"
                    :has-bottom-margin="true"
                    :tooltip-text="t('dsnapPage.incomeHelpText')"
                    @change="inputChanged"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <MoneyInput
                    name="resources"
                    :label="t('dsnapPage.resourcesInputLabel')"
                    :description="t('dsnapPage.resourcesInputDescription')"
                    :has-bottom-margin="true"
                    @change="inputChanged"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <MoneyInput
                    name="expenses"
                    :label="t('dsnapPage.expensesInputLabel')"
                    :description="t('dsnapPage.expensesInputDescription')"
                    :tooltip-text="t('dsnapPage.expensesHelpText')"
                    :has-bottom-margin="true"
                    @change="inputChanged"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <TextInput
                    type="number"
                    name="householdSize"
                    :label="t('dsnapPage.householdInputLabel')"
                    :description="t('dsnapPage.householdInputDescription')"
                    @change="inputChanged"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <button id="estimateBenefits" class="btn btn-primary" role="button">
                    <span class="fas fa-arrow-circle-down d-lg-none"></span>
                    {{ t("dsnapPage.calculateButton") }}
                    <span class="fas fa-arrow-circle-right d-none d-lg-inline"></span>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div id="benefitEstimateResult" class="card-body">
            <div class="row">
              <div class="col-12">
                <h3 class="legend text-primary mt-0">{{ t("dsnapPage.benefitEstimateTitle") }}</h3>
                <a name="benefitEstimate"></a>
                <p class="mb-2">
                  {{ t("dsnapPage.eligibilityStatement") }}
                </p>
                <p class="mb-0 text-muted">
                  {{ t("dsnapPage.estimateDisclaimer") }}
                </p>
              </div>
            </div>
            <div class="row mt-8">
              <div
                v-show="!estimate.isEligibilityCalculated"
                id="notCalculatedWarning"
                class="col-lg-12"
                aria-live="polite"
              >
                <div class="alert alert-warning bg-warning-10" role="alert">
                  <span class="fas fa-info-circle"></span>
                  {{ t("dsnapPage.householdWarningMessage") }}
                </div>
              </div>
            </div>
            <div
              v-show="estimate.isEligibilityCalculated"
              id="eligibilityCalculation"
              class="row"
              aria-live="polite"
            >
              <div v-show="!estimate.isEligible" class="col-lg-12" aria-live="polite">
                <div class="alert alert-warning bg-warning-10" role="alert">
                  <span class="fas fa-exclamation-circle mr-1"></span>
                  <span v-html="t('dsnapPage.ineligibleMessage')"></span>
                </div>
              </div>
              <div v-show="estimate.isEligible" class="col-lg-12" aria-live="polite">
                <div class="alert alert-success bg-success-10" role="alert">
                  <p class="mb-2">
                    <span class="fas fa-info-circle mr-1"></span>
                    <span
                      v-html="
                        t('dsnapPage.eligibleMessage', {
                          maxAllotment: $filters.currency(estimate.maximumAllotment),
                        })
                      "
                    >
                    </span>
                  </p>
                  <p v-html="t('dsnapPage.visitDsnapMessage')"></p>
                </div>
              </div>
            </div>
            <div v-show="estimate.isEligibilityCalculated" class="row" aria-live="polite">
              <div class="col-lg-12">
                <h4 class="h5">{{ t("dsnapPage.resultTitle") }}</h4>
                <div class="mt-2">
                  <ol>
                    <li
                      class="mb-2"
                      v-html="
                        t('dsnapPage.resultStepOneMessage', {
                          income: $filters.currency(estimate.income),
                          resources: $filters.currency(estimate.resources),
                          totalIncome: $filters.currency(estimate.totalIncome),
                        })
                      "
                    ></li>
                    <li
                      class="mb-2"
                      v-html="
                        t('dsnapPage.resultStepTwoMessage', {
                          expenses: $filters.currency(estimate.expenses),
                          totalIncome: $filters.currency(estimate.totalIncome),
                          adjustedIncome: $filters.currency(estimate.adjustedIncome),
                        })
                      "
                    ></li>
                    <li
                      v-show="estimate.isEligible"
                      aria-live="polite"
                      v-html="
                        t('dsnapPage.resultEligibleMessage', {
                          adjustedIncome: $filters.currency(estimate.adjustedIncome),
                          householdSize: $filters.defaultNumber(estimate.householdSize),
                          incomeLimit: $filters.currency(estimate.incomeLimit),
                          maxAllotment: $filters.currency(estimate.maximumAllotment),
                        })
                      "
                    ></li>
                    <li
                      v-show="!estimate.isEligible"
                      aria-live="polite"
                      v-html="
                        t('dsnapPage.resultIneligibleMessage', {
                          adjustedIncome: $filters.currency(estimate.adjustedIncome),
                          householdSize: $filters.defaultNumber(estimate.householdSize),
                          incomeLimit: $filters.currency(estimate.incomeLimit),
                        })
                      "
                    ></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { Form } from "vee-validate";
import * as Yup from "yup";
import { useI18n } from "vue-i18n";
import PageTitle from "../../views/PageTitleView.vue";
import dsnapBenefitEstimator from "./dsnapBenefitEstimator";
import MoneyInput from "../../components/MoneyInput.vue";
import TextInput from "../../components/TextInput.vue";

export default {
  name: "DsnapPage",
  components: {
    PageTitle,
    Form,
    MoneyInput,
    TextInput,
  },
  setup() {
    const { t } = useI18n();

    const household = reactive({
      income: "",
      resources: "",
      expenses: "",
      householdSize: "",
    });

    const calculateText = ref(t("dsnapPage.calculateButton"));

    const schema = Yup.object().shape({
      income: Yup.number()
        .moreThan(-1, t("dsnapPage.incomeValidationMoreThan"))
        .required()
        .typeError(t("dsnapPage.incomeValidationRequired")),
      resources: Yup.number()
        .moreThan(-1, t("dsnapPage.resourcesValidationMoreThan"))
        .required()
        .typeError(t("dsnapPage.resourcesValidationRequired")),
      expenses: Yup.number()
        .moreThan(-1, t("dsnapPage.expensesValidationMoreThan"))
        .required()
        .typeError(t("dsnapPage.expensesValidationRequired")),
      householdSize: Yup.number()
        .moreThan(0, t("dsnapPage.householdValidationMoreThan"))
        .required()
        .typeError(t("dsnapPage.householdValidationRequired")),
    });

    const { estimate, estimateBenefits } = dsnapBenefitEstimator(household);

    return {
      t,
      household,
      estimate,
      estimateBenefits,
      calculateText,
      schema,
    };
  },  
  methods: {
    inputChanged() {
      if (this.estimate.isEligibilityCalculated) {
        this.calculateText = this.t("dsnapPage.recalculateButton");
      } else {
        this.calculateText = this.t("dsnapPage.calculateButton");
      }
    },
    calculateBenefits(values) {
      this.household.income = values.income;
      this.household.resources = values.resources;
      this.household.expenses = values.expenses;
      this.household.householdSize = values.householdSize;

      this.estimateBenefits();

      document.getElementById("benefitEstimateResult").scrollIntoView();
    },
    onInvalidSubmit() {},
  },
  mounted() {
    $(function () {
        $.noConflict();
        $("[data-toggle='popover']").popover();
        $("[data-toggle='tooltip']").tooltip();
      });
  }
};
</script>
