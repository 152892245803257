const householdData = {
  additionalMemberIncomeLimit: 429,
  additionalMemberAllotment: 219,
  households: [
    {
      size: 1,
      incomeLimit: 2085,
      maximumAllotment: 291,
    },
    {
      size: 2,
      incomeLimit: 2514,
      maximumAllotment: 535,
    },
    {
      size: 3,
      incomeLimit: 2942,
      maximumAllotment: 766,
    },
    {
      size: 4,
      incomeLimit: 3380,
      maximumAllotment: 973,
    },
    {
      size: 5,
      incomeLimit: 3845,
      maximumAllotment: 1155,
    },
    {
      size: 6,
      incomeLimit: 4308,
      maximumAllotment: 1386,
    },
    {
      size: 7,
      incomeLimit: 4736,
      maximumAllotment: 1532,
    },
    {
      size: 8,
      incomeLimit: 5165,
      maximumAllotment: 1751,
    },
  ],
};

export default householdData;
