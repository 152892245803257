import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import currencyFilter from "./shared/currencyFilter";
import defaultNumberFilter from "./shared/defaultNumberFilter";
import i18n from "@/i18n/index";

import "@la-ots/pelican/dist/css/pelican.min.css";
import "@la-ots/pelican";

const app = createApp(App);

app.config.globalProperties.$filters = {
  currency: currencyFilter,
  defaultNumber: defaultNumberFilter,
};

app.use(router).use(i18n).mount("#app");
