<template>
  <GovBanner />
  <EventBanner />
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import GovBanner from "./views/GovBannerView.vue";
import EventBanner from "./views/EventBannerView.vue";
import Header from "./views/HeaderView.vue";
import Footer from "./views/FooterView.vue";

export default {
  name: "App",
  components: { GovBanner, Header, Footer, EventBanner },
};
</script>
