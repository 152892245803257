<template>
  <footer class="footer bg-black mt-auto">
    <div class="container-fluid" role="navigation" aria-label="Footer navigation">
      <div class="d-flex flex-wrap justify-content-center py-4" role="navigation">
        <router-link
          class="btn btn-link text-white border-0 rounded-0"
          :to="{ name: 'Home', params: { locale: locale } }"
        >
          {{ t("routes.home") }}
        </router-link>
        <router-link
          class="btn btn-link text-white border-0 rounded-0"
          :to="{ name: 'DSNAP', params: { locale: locale } }"
          exact
        >
          {{ t("routes.dsnap") }}
        </router-link>
        <a
          class="btn btn-link text-white border-0 rounded-0"
          href="http://dcfs.louisiana.gov/page/family-support"
          target="_blank"
          >{{ t("routes.moreInfo") }}</a
        >
        <a
          class="btn btn-link text-white border-0 rounded-0"
          href="http://www.dcfs.louisiana.gov/page/nondiscrimination-statement"
          target="_blank"
          >{{ t("routes.nonDiscrimination") }}</a
        >
      </div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t, locale } = useI18n();

    return { t, locale };
  },
};
</script>
