<template>
  <div class="form-group" :class="{ 'mb-8': hasBottomMargin }">
    <label :for="name" class="mb-0">
      <span class="fas fa-asterisk text-danger"></span>
      {{ label }}
    </label>
    <div class="mb-1 text-muted">
      {{ description }}
    </div>
    <input
      :id="name"
      :name="name"
      :type="type"
      class="form-control"
      :class="{ 'is-invalid': !!errorMessage, success: meta.valid }"
      @input="handleChange"
      @blur="handleBlur"
    />
    <div v-show="errorMessage">
      <small class="text-danger">
        {{ errorMessage }}
      </small>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    hasBottomMargin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.value,
    });

    return {
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style scoped>
.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-text.is-invalid {
  border-color: #b00003;
  color: #b00003;
}
</style>
