<template>
  <div
    class="hero-text-color-toned-photo dsnap-hero"
  >
    <div class="overlay bg-accent opacity-85 position-absolute position-0"></div>
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-12 col-md-5 col-lg-4">
          <h2 class="hero-big-text">
            {{ t("homePage.title") }}
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-5 col-lg-4">
          <p class="hero-small-text">
            {{ t("homePage.subtitle") }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="feature-cta bg-primary">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-10 col-md-6">
          <h2 class="feature-big-text text-white">{{ t("homePage.getStarted") }}</h2>
          <p class="feature-small-text text-white">
            {{ t("homePage.getStartedBody") }}
          </p>
        </div>
        <div class="col-10 col-md-6 text-right">
          <router-link
            class="btn btn-white btn-lg btn-shadow"
            :to="{ name: 'DSNAP', params: { locale: $i18n.locale } }"
          >
            {{ t("homePage.dsnapButtonText") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();

    return { t };
  },
};
</script>

<style scoped>
.dsnap-hero {
  background-image: url("../../assets/img/DSNAP-Banner.png");
  padding: 16rem 0 7rem;
}

.position-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
