<template>
  <div class="col-lg-5 order-lg-1 text-lg-right">
    <div class="btn-group ml-n2 ml-lg-2" role="group" aria-label="Language Selector Buttons">
      <button
        id="Language-Use-English"
        name="Language-Use-English"
        type="button"
        class="btn btn-link border-0 bg-transparent shadow-none btn-sm text-white rounded-0"
        @click="changeLocale('en')"
      >
        English
      </button>
      <button
        id="Language-Use-Espanol"
        name="Language-Use-Espanol"
        type="button"
        class="btn btn-link border-0 bg-transparent shadow-none btn-sm text-white rounded-0"
        @click="changeLocale('es')"
      >
        español
      </button>
      <button
        id="Language-Use-Vietnamese"
        name="Language-Use-Vietnamese"
        type="button"
        class="btn btn-link border-0 bg-transparent shadow-none btn-sm text-white rounded-0"
        @click="changeLocale('vi')"
      >
        Tiếng Việt
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.replace({ params: { locale } }).catch(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>
