import { createRouter, createWebHistory, RouterView } from "vue-router";

import HomePage from "../features/home/HomePage.vue";
import DsnapPage from "../features/dsnap/DsnapPage.vue";
import i18n from "@/i18n";

const routes = [
  {
    path: "/:locale?",
    component: RouterView,
    beforeEnter: (to, from, next) => {
      const { locale } = to.params;

      if (!i18n.global.availableLocales.includes(locale)) {
        return next(i18n.global.locale.value);
      }

      if (i18n.global.locale.value !== locale) {
        i18n.global.locale.value = locale;
      }

      return next();
    },
    children: [
      {
        path: "",
        name: "Home",
        component: HomePage,
      },
      {
        path: "dsnap",
        name: "DSNAP",
        component: DsnapPage,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect() {
      return "en";
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
